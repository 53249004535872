import axios from 'axios';
import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const AdminStore = {
  namespaced: true,

  state: {
    logs: [],
    adminLogs: []
  },

  mutations: {
    setLogs(state, logs) {
      state.logs = logs;
    },

    setAdminLogs(state, adminLogs) {
      state.adminLogs = adminLogs;
    }
  },

  actions: {
    async getLogs({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'logs';
        const logs = await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setLogs', logs.data);
      } catch (error) {
        showError(error, 'getLogs');
      }
    },

    async addLog({commit}, log) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'logs/add';
        const request = await axios.post(endpoint.prod + operationId, {log}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        const errorId = request.data.errorId || '';
        return errorId;
      } catch (error) {
        // NOOP dont use showError because of loop
      }
    },

    async deleteLogs({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'logs/delete';
        await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setLogs', []);
      } catch (error) {
        showError(error, 'deleteLogs');
      }
    },

    async updateShips({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'admin/updateShips';
        await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateShips');
      }
    },

    async updateUsers({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'admin/updateusers';
        await axios.post(endpoint.prod + operationId, {}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateUsers');
      }
    },

    async getAdminLogs({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'admin/logs';
        const request = await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setAdminLogs', request.data);
      } catch (error) {
        showError(error, 'getAdminLogs');
      }
    },

    async getAdminLog({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'admin/log';
        const request = await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        return request.data;
      } catch (error) {
        showError(error, 'getAdminLog');
      }
    },

    async addAdminLog({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'admin/logs/add';
        await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'addAdminLog');
      }
    }
  },

  getters: {
    getLogs(state) {
      return state.logs;
    },

    getAdminLogs(state) {
      return state.adminLogs;
    }
  }
};

export default AdminStore;
