<template>
  <v-navigation-drawer
    v-model="openSidebar"
    v-resize="_onResize"
    class="sidebar"
    :height="height"
    :mini-variant="miniVariante"
    :width="width"
    :mini-variant-width="miniWidth"
    :permanent="permanent"
    app
    @transitionend="$emit('resizeSidebar')"
    @input="$emit('updateSidebar', $event)"
  >
    <v-list-item class="my-2">
      <v-list-item-content>
        <v-list-item-title class="title d-flex justify-center align-center" @click="_toggleMiniVariante">
          <img v-if="logoIcon && miniVariante" :src="logoIcon" width="26" />
          <img v-if="logo && !miniVariante" :src="logo" width="80" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list v-if="navItems" dense nav>
      <nav-item
        v-for="(item, i) in navItems"
        :key="i"
        :item="item"
        :mini-variante="miniVariante"
        @click="$emit($event)"
      >
        <template v-if="item.menu">
          <v-menu offset-x open-on-click :open-delay="openMenuDelay" :activator="`#menu-${item.menuId}`">
            <v-list>
              <nav-item
                v-for="(item, i) in item.childs"
                :key="i"
                :item="item"
                @click="$emit($event)"
              ></nav-item>
            </v-list>
          </v-menu>
        </template>
      </nav-item>
    </v-list>

    <v-list v-if="appendNavItems" slot="append" dense nav>
      <v-switch v-model="$vuetify.theme.dark" style="margin-left: 6px;" inset small></v-switch>
      <nav-item
        v-for="(item, i) in appendNavItems"
        :key="i"
        :item="item"
        :mini-variante="miniVariante"
        @click="$emit($event)"
      ></nav-item>

      <user-stack :user="userStackUser" :nav-items="userStackNavItems"></user-stack>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import NavItem from './nav-item.vue';
  import UserStack from './user-stack.vue';

  export default {
    name: 'Sidebar',

    components: {
      UserStack,
      NavItem
    },

    props: {
      open: Boolean,
      width: Number,
      mini: Boolean,
      miniWidth: {
        type: Number,
        default: 70
      },
      noMiniToggle: {
        type: Boolean,
        default: false
      },
      permanent: {
        type: Boolean,
        default: false
      },
      logo: String,
      logoIcon: String,
      userStackUser: Object,
      userStackNavItems: Array,
      items: Array,
      exact: Boolean,
      openMenuDelay: Number
    },

    data() {
      return {
        openSidebar: false,
        miniVariante: false,
        height: 0
      };
    },

    computed: {
      navItems() {
        return this.items.filter((item) => {
          return item.append !== true;
        });
      },

      appendNavItems() {
        return this.items.filter((item) => {
          return item.append === true;
        });
      }
    },

    watch: {
      open() {
        this.openSidebar = this.open;
        this._setHeight();
      },

      mini() {
        this.miniVariante = this.mini;
      },

      '$vuetify.theme.dark': function(state) {
        localStorage.setItem("dark-theme", state.toString());
      }
    },

    created() {
      this.openSidebar = this.open;
      this.miniVariante = this.mini;
    },

    beforeMount() {
      this._setHeight();
    },

    methods: {
      _toggleMiniVariante() {
        if (!this.noMiniToggle) {
          this.miniVariante = !this.miniVariante;
        } else {
          this.$emit('logo-click');
        }
      },

      _setHeight() {
        this.height = window.innerHeight;
      },

      _onResize() {
        this._setHeight();
      }
    }
  };
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
}
</style>
