import axios from 'axios';
import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const ShipsStore = {
  namespaced: true,

  state: {
    shipList: [],
    ship: {},
    userShips: [],
    shipFilterStore: {
      shipName: '',
      shipIds: [],
      manufacturerIds: [],
      size: []
    },
    shoppingCardStore: []
  },

  mutations: {
    setShipList(state, shipList) {
      state.shipList = shipList;
    },

    setShip(state, ship) {
      state.ship = ship;
    },

    setUserShips(state, userShips) {
      state.userShips = userShips;
    },

    setShoppingCardStore(state, shoppingCardStore) {
      state.shoppingCardStore = shoppingCardStore;
    },

    setShipFilterStore(state, shipFilterStore) {
      state.shipFilterStore = shipFilterStore;
    }
  },

  actions: {
    async getShipList({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'shiplist';
        const shipList = await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setShipList', shipList.data);
      } catch (error) {
        showError(error, 'getShipList');
      }
    },

    async getShip({commit}, shipId) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'ship';
        const ship = await axios.post(endpoint.prod + operationId, {shipId}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setShip', ship.data.ship[0]);
        commit('setUserShips', ship.data.userShips);
      } catch (error) {
        showError(error, 'getShip');
      }
    },

    async getShips({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'ships';
        const ships = await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        return ships.data;
      } catch (error) {
        showError(error, 'getShip');
      }
    },

    async updateShips({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'ship/update';
        await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateShips');
      }
    },

    async resetShipsData({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'ship/reset';
        await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'resetShipsData');
      }
    },


    async setShoppingCardStore({commit}, shoppingCardStore) {
      commit('setShoppingCardStore', shoppingCardStore);
    },

    async setShipFilterStore({commit}, shipFilterStore) {
      commit('setShipFilterStore', shipFilterStore);
    },

    async resetShipFilterStore({commit}) {
      commit('setShipFilterStore', {
        shipName: '',
        shipIds: [],
        manufacturerIds: [],
        size: []
      });
    }
  },

  getters: {
    getShipList(state) {
      return state.shipList;
    },

    getShip(state) {
      return state.ship;
    },

    getUserShips(state) {
      return state.userShips;
    },

    getShipFilterStore(state) {
      return state.shipFilterStore;
    },

    getShoppingCardStore(state) {
      return state.shoppingCardStore;
    }
  }
};

export default ShipsStore;
