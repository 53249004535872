import axios from 'axios';
import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const UserStore = {
  namespaced: true,

  state: {
    user: {},
    profile: {},
    users: []
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },

    setProfile(state, profile) {
      state.profile = profile;
    },

    removeUser(state) {
      state.user = {};
    },

    setUsers(state, users) {
      state.users = users;
    }
  },

  actions: {
    async login({commit}, user) {
      commit('setUser', user);
    },

    logout({commit}) {
      commit('removeUser');
    },

    async getUser({commit}, sub) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'user';
        const user = await axios.post(endpoint.prod + operationId, {sub}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setUser', user.data);
      } catch (error) {
        showError(error, 'getUser');
      }
    },

    async getProfile({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'profile';
        const user = await axios.post(endpoint.prod + operationId, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setProfile', user.data);
      } catch (error) {
        showError(error, 'getProfile');
      }
    },

    async getUsers({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'users';
        const users = await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setUsers', users.data);
      } catch (error) {
        showError(error, 'getUsers');
      }
    },

    async updateEmail({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'user/update/email';
        await axios.post(endpoint.prod + operationId, {data: {
          ...data,
          token: accessToken
        }}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateEmail');
      }
    },

    async updateUser({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'user/update';
        await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateUser');
      }
    },

    async resetPassword({commit}, email) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'user/resetpw';
        await axios.post(endpoint.prod + operationId, {email}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'resetPassword');
      }
    },

    async deleteUser({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'user/delete';
        await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'deleteUser');
      }
    }
  },

  getters: {
    getUser(state) {
      return state.user;
    },

    getProfile(state) {
      return state.profile;
    },

    isAuthenticated(state) {
      return state.isAuthenticated;
    },

    getUsers(state) {
      return state.users;
    }
  }
};

export default UserStore;
