<template>
  <v-avatar>
    <v-img v-if="avatar" :src="avatar" contain max-width="40" />
    <span v-if="!avatar && name" class="caption font-weight-medium" :style="{color: textColor}">{{getNameInitials(name)}}</span>
    <span v-if="!avatar && !name"><v-icon :color="textColor" small>far fa-user</v-icon></span>
  </v-avatar>
</template>

<script>
  export default {
    name: 'Avatar',

    props: {
      color: String,
      textColor: {
        type: String, // HEX
        default: 'white'
      },
      avatar: String,
      name: String
    },

    methods: {
      getNameInitials() {
        let initials = [];

        if (this.name) {
          const segments = this.name.split(' ');

          if (segments.length > 1) {
            for (let i = 0; i < 2; i++) {
              const segment = segments[i];
              initials.push(segment.substr(0, 1));
            }

            return initials.join('').toUpperCase();
          } else {
            return segments[0].substr(0, 1);
          }
        } else {
          return false;
        }
      }
    }
  };
</script>
