<template>
  <v-list-item
    :id="navItems && 'userstack'"
    :link="navItems && true"
    two-line
  >
    <v-list-item-avatar>
      <avatar :avatar="user.image" :name="user.name" color="primary"></avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title :title="user.name">{{user.name}}</v-list-item-title>
      <v-list-item-subtitle :title="user.email">{{user.email}}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="navItems">
      <v-icon small>fad fa-sort-up</v-icon>
    </v-list-item-action>

    <v-menu activator="#userstack" offset-x>
      <v-list v-if="navItems">
        <nav-item v-for="(item, i) in navItems" :key="i" :item="item"></nav-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>

<script>
  import NavItem from './nav-item.vue';
  import Avatar from './avatar.vue';

  export default {
    name: 'UserStack',

    components: {
      NavItem,
      Avatar
    },

    props: {
      user: {
        type: Object,
        required: true
      },
      navItems: Array
    },

    methods: {
      setTitle(title) {
        if (typeof title === 'object') {
          if (title.label && title.i18n) {
            return this.$t(title.label);
          } else {
            return title.label;
          }
        } else {
          return title;
        }
      }
    }
  };
</script>
