import axios from 'axios';
import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const ShipsStore = {
  namespaced: true,

  state: {
    manufacturers: [],
    manufacturer: {},
    ships: []
  },

  mutations: {
    setManufacturers(state, manufacturers) {
      state.manufacturers = manufacturers;
    },

    setManufacturer(state, manufacturer) {
      state.manufacturer = manufacturer;
    },

    setShips(state, ships) {
      state.ships = ships;
    }
  },

  actions: {
    async getManufacturers({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'manufacturers';
        const manufacturers = await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setManufacturers', manufacturers.data);
      } catch (error) {
        showError(error, 'getManufacturers');
      }
    },

    async getManufacturer({commit}, manufacturerId) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'manufacturer';
        const manufacturer = await axios.post(endpoint.prod + operationId, {manufacturerId}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setManufacturer', manufacturer.data);
      } catch (error) {
        showError(error, 'getManufacturer');
      }
    },

    async getShips({commit}, manufacturerId) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'manufacturer/ships';
        const ships = await axios.post(endpoint.prod + operationId, {manufacturerId}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setShips', ships.data);
      } catch (error) {
        showError(error, 'getShips');
      }
    }
  },

  getters: {
    getManufacturers(state) {
      return state.manufacturers;
    },

    getManufacturer(state) {
      return state.manufacturer;
    },

    getShips(state) {
      return state.ships;
    }
  }
};

export default ShipsStore;
