import Vue from 'vue';
import Router from 'vue-router';
import {authGuard} from '../auth/authGuard';
const errorComponent = () => import('../views/Error');

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/LandingPage.vue')
    },
    {
      path: '/widget',
      name: 'widget',
      component: () => import('../views/Widget')
    },
    // {
    //   path: '/mining-calculator',
    //   name: 'mining-calculator',
    //   component: () => import('../views/MiningCalculator')
    // },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('../views/Admin.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../views/Dashboard.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/hangar',
      name: 'hangar',
      component: () => import('../views/Hangar.vue'),
      props: (route) => (
        {
          userId: route.query.userId
        }
      ),
      beforeEnter: authGuard
    },
    {
      path: '/hangar/ship',
      name: 'HangarShipDetail',
      component: () => import('../views/HangarShipDetail.vue'),
      props: (route) => (
        {
          hangarShipId: route.query.hangarShipId
        }
      ),
      beforeEnter: authGuard
    },
    {
      path: '/ships',
      name: 'ships',
      component: () => import('../views/Ships.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/ship',
      name: 'ShipDetail',
      component: () => import('../views/ShipDetail.vue'),
      props: (route) => (
        {
          shipId: route.query.shipId
        }
      ),
      beforeEnter: authGuard
    },
    {
      path: '/manufacturer',
      name: 'ManufacturerDetail',
      component: () => import('../views/ManufacturerDetail.vue'),
      props: (route) => (
        {
          manufacturerId: route.query.manufacturerId
        }
      ),
      beforeEnter: authGuard
    },
    {
      path: '/members',
      name: 'members',
      component: () => import('../views/Members.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('../views/User.vue'),
      beforeEnter: authGuard,
      props: (route) => (
        {
          handle: route.query.handle,
          userId: route.query.userId
        }
      ),
    },
    {
      path: '/page-settings',
      name: 'page-settings',
      component: () => import('../views/PageSettings.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/playlist',
      name: 'playlist',
      component: () => import('../views/Playlist.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/tools',
      name: 'tools',
      component: () => import('../views/Tools.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/error',
      name: 'error',
      component: errorComponent,
      props: {
        status: 404,
        title: 'error.404.title',
        message: 'error.404.message'
      }
    },
    {
      path: '/403',
      name: '403',
      component: errorComponent,
      props: {
        status: 403,
        title: 'error.403.title',
        message: 'error.403.message'
      }
    },
    {
      path: '/500',
      name: '500',
      component: errorComponent,
      props: {
        status: 500,
        title: 'error.500.title',
        message: 'error.500.message'
      }
    },
    {
      path: '*',
      name: '404',
      component: errorComponent,
      props: {
        status: 404,
        title: 'error.404.title',
        message: 'error.404.message'
      }
    }
  ],
});
