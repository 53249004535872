import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    },
    dark: true,
    options: {
      minifyTheme,
      themeCache: {
        // eslint-disable-next-line no-undef
        get: (key) => localStorage.getItem(key),
        // eslint-disable-next-line no-undef
        set: (key, value) => localStorage.setItem(key, value)
      }
    }
  }
};

export default new Vuetify(opts);
