<template>
  <v-theme-provider root>
    <v-app>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <sidebar
        v-if="$auth.isAuthenticated && ['admin', 'dashboard', 'hangar', 'HangarShipDetail', 'ships', 'ShipDetail', 'ManufacturerDetail', 'members', 'user', 'page-settings', 'playlist', 'tools'].includes($route.name)"
        ref="sidebar"
        :items="navItems"
        :open="openSidebar"
        :mini="miniSidebar"
        :user-stack-user="user"
        :logo="require('./assets/images/logo.png')"
        :logo-icon="require('./assets/images/logo.png')"
        @updateSidebar="openSidebar = $event"
        @resizeSidebar="resizeSidebar"
        @logout="logout"
      />
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </v-theme-provider>
</template>


<script>
  import Sidebar from './components/Sidebar/index';
  import {eventBus} from './main';

  export default {
    name: 'App',

    components: {
      Sidebar
    },

    data() {
      return {
        openSidebar: true,
        miniSidebar: true,
        navItems: [
          {label: 'Dashboard', icon: 'fa-chart-bar', to: '/dashboard'},
          {label: 'Hangar', icon: 'fa-bookmark', to: '/hangar'},
          {label: 'Ships', icon: 'fa-fighter-jet', to: '/ships'},
          {label: 'Members', icon: 'fa-users', to: '/members'},
          // {label: 'Page-Settings', icon: 'fa-stopwatch', to: '/page-settings'},
          // {label: 'Playlist', icon: 'fab fa-discord', to: '/playlist'},
          // {label: 'Tools', icon: 'fa-robot', to: '/tools'},
          {label: 'Admin', icon: this.user && this.user.rank === 'Wildest One' ? 'fa-unlock' : 'fa-lock', to: '/admin'},
          {label: 'User', icon: 'fa-user', append: true, event: 'moveToUser'},
          {label: 'Logout', icon: 'fa-sign-out-alt', append: true, event: 'logout'}
        ]
      };
    },
    computed: {
      user() {
        return this.$store.getters['user/getUser'];
      },
      loading() {
        return this.$store.getters.loading;
      }
    },

    mounted() {
      const theme = localStorage.getItem("dark-theme");
      if (theme) {
        this.$vuetify.theme.dark = JSON.parse(theme);
      }
    },

    watch: {
      user(user) {
        this.navItems = [
          {label: 'Dashboard', icon: 'fa-chart-bar', to: '/dashboard'},
          {label: 'Hangar', icon: 'fa-bookmark', to: '/hangar'},
          {label: 'Ships', icon: 'fa-fighter-jet', to: '/ships'},
          {label: 'Members', icon: 'fa-users', to: '/members'},
          // {label: 'Page-Settings', icon: 'fa-stopwatch', to: '/page-settings'},
          // {label: 'Playlist', icon: 'fab fa-discord', to: '/playlist'},
          // {label: 'Tools', icon: 'fa-robot', to: '/tools'},
          {label: 'Admin', icon: user && user.rank === 'Wildest One' ? 'fas fa-unlock' : 'fas fa-lock', to: '/admin'},
          {label: 'User', icon: 'fa-user', append: true, to: `/user?handle=${this.user.handle}`},
          {label: 'Logout', icon: 'fa-sign-out-alt', append: true, event: 'logout'}
        ]
      },
      $route (to){
        if (['access_denied', 'unauthorized'].includes(to.query.error)) {
          this.$swal({
            title: 'Zugriff verweigert!',
            text: to.query.error_description,
            icon: 'error',
            showConfirmButton: true
          });
        }
      }
    },

    created() {
      eventBus.$on('toggleSidebar', (payload) => {
        if (payload) {
          this.openSidebar = payload;
          return;
        }
        this.openSidebar = !this.openSidebar;
      });
      eventBus.$on('showError', async (errorData) => {
        if (this.$route.name !== 'dashboard') {
          this.$router.push('dashboard');
        }
        const errorId = await this.$store.dispatch('admin/addLog', errorData);
        let errorCode = '30000';
        if (errorId) {
          errorCode = '20000';
        }
        this.$swal({
          html: `<div style="color: white; text-align: left;"><div>ERROR - Disconnection (CODE ${errorCode})</div>` + '<div>Connection to the server was lost unexpectedly.</div>' + `<div>If the problem persists please send this "${errorId ? errorId : errorData.description}" to your Admin</div></div>`,
          background: '#061019',
          showCloseButton: false,
          showConfirmButton: false,
          backdrop: `
            black
          `
        });
      });
    },

    methods: {
      resizeSidebar() {
        const event = new CustomEvent('resizeSidebar');
        window.dispatchEvent(event);
      },
      logout() {
        this.$store.dispatch('user/logout');
        this.$auth.logout({
          returnTo: window.location.origin
        });
      },
    }
  };
</script>

<style lang="scss">
  @import './assets/scss/app.scss';
</style>
