import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const PlaylistStore = {
  namespaced: true,

  state: {
    items: [],
    loading: false
  },

  mutations: {
    setItems(state, items) {
      state.items = items;
    },

    deleteItem(state, item) {
      state.items = state.items.filter((itm) => itm.id !== item.id);
    }
  },


  actions: {
    // eslint-disable-next-line no-unused-vars
    async getPlaylist({commit}, payload) {
      const parameters = {
        providerId: payload.providerId,
        channelId: payload.channelId
      };
      try {
        // eslint-disable-next-line no-undef
        const response = await api.call(this, commit, 'CampaignsList', parameters);
        const operationId = 'logs';
        commit('setCampaigns', response.data.results || []);
      } catch (error) {
        showError(error, 'getPlaylist');
      }
    },

    async deletePlaylistItem({commit}, payload) {
      const parameters = {
        providerId: payload.providerId,
        channelId: payload.channelId
      };
      try {
        // eslint-disable-next-line no-undef
        const response = await api.call(this, commit, 'CampaignsList', parameters);
        const operationId = 'logs';
        commit('setCampaigns', response.data.results || []);
      } catch (error) {
        showError(error, 'deletePlaylistItem');
      }
    }
  },

  getters: {
    getItems(state) {
      return state.items;
    }
  }
};

export default PlaylistStore;
