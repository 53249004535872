import Vuex from 'vuex';
import UserStore from './modules/user';
import DashboardStore from './modules/dashboard';
import HangarStore from './modules/hangar';
import ShipsStore from './modules/ships';
import ManufacturersStore from './modules/manufacturers';
import PlaylistStore from './modules/playlist';
import ToolsStore from './modules/tools';
import AdminStore from './modules/admin';


export default class Store {

  constructor(Vue) {
    Vue.use(Vuex);

    const store = new Vuex.Store({
      state: {
        loading: false
      },

      mutations: {
        setLoading(state, status) {
          state.loading = status;
        }
      },

      actions: {
        loading({commit}, status) {
          commit('setLoading', status);
        }
      },

      getters: {
        loading(state) {
          return state.loading;
        }
      },

      modules: {
        user: UserStore,
        dashboard: DashboardStore,
        hangar: HangarStore,
        ships: ShipsStore,
        manufacturers: ManufacturersStore,
        playlist: PlaylistStore,
        tools: ToolsStore,
        admin: AdminStore
      }
    });

    Vue.store = store;

    return store;
  }
}
