import axios from 'axios';
import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const DashboardStore = {
  namespaced: true,

  state: {
    dashboard: {
      chart: [],
      ships: []
    }
  },

  mutations: {
    setDashboard(state, data) {
      state.dashboard = {
        chart: data.chart,
        ships: data.ships,
        hangarSize: data.hangarSize
      };
    }
  },

  actions: {
    async getDashboard({commit}) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'dashboard';
        const dashboard = await axios.post(endpoint.prod + operationId, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setDashboard', {
          chart: dashboard.data.chart,
          ships: dashboard.data.ships,
          hangarSize: dashboard.data.hangarSize
        });
      } catch (error) {
        showError(error, 'getDashboard');
      }
    }
  },

  getters: {
    getDashboard(state) {
      return state.dashboard;
    }
  }
};

export default DashboardStore;
