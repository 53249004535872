import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Store from './store';
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {Settings} from 'luxon';
import {domain, clientId, audience} from '../auth_config.json';
import {Auth0Plugin} from './auth';
import locales from './locales';
import VueI18n from 'vue-i18n';

Settings.defaultLocale = 'de';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: locales
});

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: () => {
    router.push({name: 'dashboard'});
  }
});

const sweetalertOptions = {
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33'
};

Vue.use(Datetime);
Vue.use(VueSweetalert2, sweetalertOptions);

Vue.config.productionTip = true;

const store = new Store(Vue);
export const eventBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
