import axios from 'axios';
import {showError} from '../error';
import {endpoint} from '../../../auth_config.json';


const HangarStore = {
  namespaced: true,

  state: {
    hangar: [],
    hangarUsers: [],
    ship: {},
    userShips: [],
    shipFilterStore: {
      shipName: '',
      shipIds: [],
      manufacturerIds: [],
      size: []
    }
  },

  mutations: {
    setHangar(state, hangar) {
      state.hangar = hangar;
    },

    setHangarUsers(state, hangarUsers) {
      state.hangarUsers = hangarUsers;
    },

    setShip(state, ship) {
      state.ship = ship;
    },

    setUserShips(state, userShips) {
      state.userShips = userShips;
    },

    setShipFilterStore(state, shipFilterStore) {
      state.shipFilterStore = shipFilterStore;
    }
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async addShips({commit}, ships) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'hangar/add';
        await axios.post(endpoint.prod + operationId, {data: ships}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'addShips');
      }
    },

    async getHangar({commit}, data) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'hangar';
        const hangar = await axios.post(endpoint.prod + operationId, {data}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setHangarUsers', hangar.data.hangarUsers);
        commit('setHangar', hangar.data.hangarShips);
      } catch (error) {
        showError(error, 'getHangar');
      }
    },

    async getShip({commit}, hangarShipId) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'hangar/ship';
        const ship = await axios.post(endpoint.prod + operationId, {hangarShipId: hangarShipId}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        commit('setShip', ship.data.userShip);
        commit('setUserShips', ship.data.userShips);
      } catch (error) {
        showError(error, 'getShip');
      }
    },

    async updateShips({commit}, shipsData) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'hangar/update';
        await axios.post(endpoint.prod + operationId, {data: shipsData}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateShip');
      }
    },

    async deleteShips({commit}, hangarShipIds) {
      try {
        const accessToken = await this._vm.$auth.getTokenSilently();
        const operationId = 'hangar/delete';
        await axios.post(endpoint.prod + operationId, hangarShipIds, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        showError(error, 'updateShip');
      }
    },

    async setShipFilterStore({commit}, shipFilterStore) {
      commit('setShipFilterStore', shipFilterStore);
    },

    async resetShipFilterStore({commit}) {
      commit('setShipFilterStore', {
        shipName: '',
        shipIds: [],
        manufacturerIds: [],
        size: []
      });
    }
  },

  getters: {
    getHangar(state) {
      return state.hangar;
    },

    getHangarUsers(state) {
      return state.hangarUsers;
    },

    getShip(state) {
      return state.ship;
    },

    getUserShips(state) {
      return state.userShips;
    },

    getShipFilterStore(state) {
      return state.shipFilterStore;
    }
  }
};

export default HangarStore;
