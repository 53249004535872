<template>
  <div>
    <v-list-item
      :id="item.menu && `menu-${item.menuId}`"
      :class="{'hidden-md-and-down': item.hideOnMobile, 'hidden-md-and-up': item.showOnMobile, 'menu': item.menu}"
      class="mb-2"
      :exact="exact"
      :to="item.to ? item.to : null"
      :href="item.href ? item.href : null"
      :target="item.target ? item.target : '_self'"
      @click="item.event ? $emit('click', item.event) : null"
    >
      <v-badge
        v-if="item.count && miniVariante"
        color="accent"
        :content="item.count && item.count <= 9 ? item.count : `9+`"
        :offset-x="-12"
      ></v-badge>

      <v-list-item-icon class="d-flex justify-center" :class="{'expand': !miniVariante}">
        <v-icon>{{item.icon}}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-badge
          v-if="item.count && !miniVariante"
          color="accent"
          :content="item.count && item.count <= 9 ? item.count : `9+`"
          inline
        >
          <v-list-item-title>{{setTitle(item.label)}}</v-list-item-title>
        </v-badge>

        <v-list-item-title v-else>{{setTitle(item.label)}}</v-list-item-title>
      </v-list-item-content>

      <slot></slot>
    </v-list-item>
  </div>
</template>

<script>
  export default {
    name: 'NavItem',

    props: {
      item: Object,
      exact: Boolean,
      miniVariante: Boolean
    },

    methods: {
      setTitle(title) {
        if (typeof title === 'object') {
          if (title.label && title.i18n) {
            return this.$t(title.label);
          } else {
            return title.label;
          }
        } else {
          return title;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .theme--light.v-icon {
    color: gray;
  }

  .v-menu__content .v-list-item__icon.expand {
    margin-right: 10px !important;
  }

  .v-list-item {
    align-items: center !important;
  }

  .v-list-item__icon.expand {
    width: 35px;
    padding-left: 6px;
    margin-right: 20px !important;
    align-self: center !important;
  }

  .v-list-item--active.theme--light {
    color: white;
    background-color: #1E4066;
  }

  .v-list-item--active.theme--light .v-icon {
    color: white;
  }

  a {
    text-decoration: none !important;
  }
</style>
