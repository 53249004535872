import {eventBus} from '../main';
import {nanoid} from 'nanoid';


export const showError = (error, type) => {
  const errorId = nanoid();
  const errorData = {
    errorId,
    title: `Frontend ${type}`,
    data: error,
    description: error && error.message || error,
    type: 'error'
  };
  eventBus.$emit('showError', errorData);
};
